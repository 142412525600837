import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { routerStore } from "mr-router5";
import AppData from "_/data/app.json";
import appStore from "_/stores";
import LogoImg from "_/assets/images/logos/logo-2.png";
// import BackToTop from "./BackToTop";
import Pentagon from "./Pentagon";

function Header() {
    const { appData, setLanguage, language, authStore } = appStore;

    const [toggle, setToggle] = useState(false);

    const navItems = [];

    const { path: routePath, name: routeName } = routerStore.route;

    AppData.header.menu.forEach((item) => {
        let classes = "";

        if (item.children !== 0) {
            classes = "mil-has-children";
        }
        if ((routePath.indexOf(item.link) !== -1 && item.link !== "/") || routePath === item.link) {
            classes += " mil-active";
        }
        const newobj = { ...item, classes };
        navItems.push(newobj);
    });

    const clickedMobileMenuItemParent = (e) => {
        e.preventDefault();

        const lists = document.querySelectorAll(".mil-has-children ul");
        lists.forEach((list) => {
            list.classList.remove("mil-active");
        });

        const links = document.querySelectorAll(".mil-has-children a");
        links.forEach((link) => {
            link.classList.remove("mil-active");
        });

        e.target.classList.toggle("mil-active");
        e.target.parentNode.querySelector("ul").classList.toggle("mil-active");
    };

    // const langLabel = language === "en" ? "切換到中文" : "Switch to English";
    const langLabel = language === "en" ? "中文" : "English";

    const switchLang = () => {
        setLanguage(language === "en" ? "zh" : "en");
    };

    return (
        <>
            {/* menu */}
            <div className={`mil-menu-frame ${toggle ? "mil-active" : ""}`}>
                {/* frame clone */}
                <div className="mil-frame-top">
                    <a href={AppData.header.logo.link} className="mil-logo">{AppData.header.logo.symbol}</a>
                    <div className={`mil-menu-btn ${toggle ? "mil-active" : ""}`} onClick={() => setToggle(!toggle)}> {/* eslint-disable-line */}
                        <span />
                    </div>
                </div>
                {/* frame clone end */}
                {/* <div className="container">
                    <div className="mil-menu-content">
                        <div className="row">
                            <div className="col-xl-5">

                                <nav className="mil-main-menu" id="swupMenu">
                                    <ul>
                                        {navItems.map((item) => (
                                            <li className={item.classes} key={`header-menu-item-${item.label}`}>
                                                <a href={item.link} onClick={item.children !== 0 ? (e) => clickedMobileMenuItemParent(e) : null}>{item.label}</a>
                                                {item.children !== 0 && (
                                                    <ul>
                                                        {item.children.map((subitem) => (
                                                            <li
                                                                key={`header-submenu${item.label}-item-${subitem.label}`}
                                                                className={((routePath.indexOf(subitem.link) !== -1 && subitem.link !== "/") || routePath === subitem.link) ? "mil-active" : ""}
                                                            >
                                                                <a href={subitem.link}>{subitem.label}</a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </nav>

                            </div>
                            <div className="col-xl-7">

                                <div className="mil-menu-right-frame">
                                    <div className="mil-animation-in">
                                        <div className="mil-animation-frame">
                                            <div className="mil-animation mil-position-1 mil-scale" data-value-1="2" data-value-2="2">
                                                <Pentagon />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mil-menu-right">
                                        <div className="row">
                                            <div className="col-lg-8 mil-mb-60">

                                                <h6 className="mil-muted mil-mb-30">Projects</h6>

                                                <ul className="mil-menu-list">
                                                    <li><a href="/projects/project-1" className="mil-light-soft">Interior design studio</a></li>
                                                    <li><a href="/projects/project-2" className="mil-light-soft">Home Security Camera</a></li>
                                                    <li><a href="/projects/project-3" className="mil-light-soft">Kemia Honest Skincare</a></li>
                                                    <li><a href="/projects/project-4" className="mil-light-soft">Cascade of Lava</a></li>
                                                    <li><a href="/projects/project-5" className="mil-light-soft">Air Pro by Molekule</a></li>
                                                    <li><a href="/projects/project-6" className="mil-light-soft">Tony&#39;s Chocolonely</a></li>
                                                </ul>

                                            </div>
                                            <div className="col-lg-4 mil-mb-60">

                                                <h6 className="mil-muted mil-mb-30">Useful links</h6>

                                                <ul className="mil-menu-list">
                                                    <li><a href="#." className="mil-light-soft">Privacy Policy</a></li>
                                                    <li><a href="#." className="mil-light-soft">Terms and conditions</a></li>
                                                    <li><a href="#." className="mil-light-soft">Cookie Policy</a></li>
                                                    <li><a href="#." className="mil-light-soft">Careers</a></li>
                                                </ul>

                                            </div>
                                        </div>
                                        <div className="mil-divider mil-mb-60" />
                                        <div className="row justify-content-between">

                                            <div className="col-lg-4 mil-mb-60">

                                                <h6 className="mil-muted mil-mb-30">Canada</h6>

                                                <p className="mil-light-soft mil-up">
                                                    71 South Los Carneros Road, California
                                                    <span className="mil-no-wrap">+51 174 705 812</span>
                                                </p>

                                            </div>
                                            <div className="col-lg-4 mil-mb-60">

                                                <h6 className="mil-muted mil-mb-30">Germany</h6>

                                                <p className="mil-light-soft">
                                                    Leehove 40, 2678 MC De Lier, Netherlands
                                                    <span className="mil-no-wrap">+31 174 705 811</span>
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            {/* menu */}

            {/* curtain */}
            <div className="mil-curtain" />
            {/* curtain end */}

            {/* frame */}
            <div className="mil-frame no-invert">
                <div className="mil-frame-top">
                    <a href={appData.header.logo.link} className="mil-logo">
                        {/* {AppData.header.logo.symbol} */}
                        <img src={LogoImg} alt="Arising Ages" width="70" />
                    </a>
                    {/* <div className={`mil-menu-btn ${toggle ? "mil-active" : ""}`} onClick={() => setToggle(!toggle)}>
                            <span />
                        </div>
                    */}
                    <div className="mil-frame-top-right">
                        {!routeName.startsWith("member.") && (
                            <>
                                <a href={appData.header.logo.link} onClick={switchLang}>{langLabel}</a>
                                <span className="spacer spacer-tiny" />
                            </>
                        )}
                        {/* <a href="https://www.zeffy.com/donation-form/c1bd3aac-ffb6-4b9b-b59d-4cbe1878ea58" className="mil-button mil-button-small mil-arrow-place" target="_blank" rel="noreferrer"> */}
                        <a href="" onClick={() => routerStore.router.navigate("donation")} className="mil-button mil-button-small mil-arrow-place">
                            <span>{appData.donateButton.label}</span>
                        </a>
                        {/* <span className="spacer spacer-small" />
                        {!routeName.startsWith("member.") ? (
                            <span
                                className="fa-regular fa-user login-button"
                                onClick={() => routerStore.router.navigate("member.events")}
                                onKeyDown={() => routerStore.router.navigate("member.events")}
                                role="presentation"
                            />
                        ) : (
                            <span
                                className="fa-solid fa-right-from-bracket login-button"
                                onClick={authStore.firebaseSignOut}
                                onKeyDown={authStore.firebaseSignOut}
                                role="presentation"
                            />
                        )} */}
                    </div>
                </div>
                <div className="mil-frame-bottom">
                    <div className="mil-current-page" />
                    {/* <BackToTop /> */}
                </div>
            </div>
            {/* frame end */}
        </>
    );
}

export default observer(Header);
