import React from "react";
import appStore from "_/stores";

function Hero() {
    const { donation } = appStore.appData;

    return (
        <section className="mil-banner mil-dark-bg">
            <div className="mi-invert-fix">
                <div className="container container-page">
                    <div className="mil-page-content mil-up">
                        <h2 className="mil-muted mil-mb-60">{donation.title}</h2>

                        <div className="row">
                            <div className="col-md-7 col-lg-5">
                                <div className="mil-muted mil-mb-60">
                                    <h4 className="mil-muted">{donation.description}</h4>
                                    <br />
                                    <h5 className="mil-muted">{donation.methods[0].title}</h5>
                                    <br />
                                    <p dangerouslySetInnerHTML={{ __html: donation.methods[0].description }} /> {/* eslint-disable-line */}
                                    <br />
                                    <br />
                                    <h5 className="mil-muted">{donation.methods[1].title}</h5>
                                    <br />
                                    <p dangerouslySetInnerHTML={{ __html: donation.methods[1].description }} /> {/* eslint-disable-line */}
                                </div>
                                <h5 className="mil-muted">
                                    {donation.notes}
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
